@import '../../assets/styles/base/variables';

// #HEADER-V2
//-------------------------------------
$header-bg-color: #000;

.bi-header-v2 {
  position: relative;
  box-sizing: content-box;
  margin-bottom: $grid-offset-step * 6;
  background-color: $header-bg-color;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.02);
  z-index: 3;

  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .bi-search {
    padding-right: $grid-offset-step * 15;
  }

  &__stats {
    padding-bottom: ($grid-offset-step * 7);

    justify-content: center;
  }
}

@media screen and (max-width: $laptop-device-width) {
  .bi-header-v2 {
    &__stats {
      padding-bottom: ($grid-offset-step * 7);
    }
  }
}

@media screen and (max-width: $mobile-device-width) {
  .bi-header-v2 {
    &__mobile {
      display: flex;
    }

    .bi-search {
      padding-right: $grid-offset-step * 2;
    }
  }
}
