@import '../../assets/styles/base/variables';

// #APP
//-------------------------------------
.bi-app {
  height: 100%;

  &__wrapper {
    height: 100%;
  }

  &__body {
    // height: 100%;
  }

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
