@import '../../assets/styles/base/variables';

.footer {
  width: 100%;
  height: 202px;
  flex-shrink: 0;
  background: #FFF;
  box-sizing: border-box;
  padding: 32px 50px 24px 50px;

  .footer-header {
    display: flex;
    align-content: center;

    img {
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }

    p {
      color: #181719;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 600;
      margin-left: 8px;
    }
  }

  &>p {
    margin-top: 20px;
    color: #181719;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    width: 314px;
  }

  .footer-botton {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F1F1F1;
    padding-top: 24px;
    margin-top: 32px;
    box-sizing: border-box;

    p {
      color: #181719;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .media-box {
      img {
        width: 26px;
        height: 26px;
        flex-shrink: 0;
        margin-left: 24px;
      }
    }
  }
}